import type { UIRouter } from '@wix/tpa-router';

import { type IViewModel } from 'controller/types';

export function activity(router: UIRouter, vm: IViewModel) {
  router.transitionService.onSuccess(
    { to: 'group.**' },
    async function (transition) {
      const injector = transition.injector();

      const isJoined = await injector.getAsync<boolean>('isJoined');
      const groupId = await injector.getAsync<string>('groupId');

      if (isJoined) {
        vm.group$.resetActivityCounter(groupId);
      }
    },
    { invokeLimit: 1 },
  );
}
