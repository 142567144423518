import type { UIRouter } from '@wix/tpa-router';

import { type IViewModel } from 'controller/types';

export function autoJoin(router: UIRouter, vm: IViewModel) {
  router.transitionService.onSuccess(
    { to: true },
    async function (transition) {
      const params = transition.params();
      const injector = transition.injector();

      const isJoined = await injector.getAsync<boolean>('isJoined');
      const groupId = await injector.getAsync<string>('groupId');

      const triggered =
        params.invite ||
        params.autoInviteId ||
        params.appSectionParams?.invite === 'invite_paid_plans';

      if (!isJoined && triggered) {
        void vm.group$.join({
          groupId,
          autoInviteId: params.autoInviteId,
        });
      }
    },
    { invokeLimit: 1 },
  );
}
